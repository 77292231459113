.img{
  height:125px;
  width: 125px;
}

.additionalLink{
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.title{
  color: #F3F3F3;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}