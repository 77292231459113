.container {
  text-decoration: none;
  width: 100%;
}


.img {
  width: 100%;
  max-height: 700px;
}

.date {
  color: black;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.title {
  color: black;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.skeleton {
  width: 100%;
}