.navigationTitle {
  font-weight: 700;
}


.navigationContainer {
  font: {
    weight: 400;
    size: 14px;
  };
  color: black;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 80%;
}

.navigationElement {
  cursor: pointer;
  text-decoration: none;
  color: black;
}