.container {
  margin-top: 30px;
  display: flex;
  gap: 5px;
  justify-content: center;
  list-style-type: none;
}

.container > li > a {
  padding: 10px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  color: black;
  border-radius: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e7e7;
  font-size: 14px;

  &:hover {
    scale: 1.05;
    transition-duration: 200ms;
    cursor: pointer;
    background: #a1a1a1;
  }

}

.some {
  background: red;
}


.paginationBtn_active {
  background: linear-gradient(#335a3e, #174022);
  border: none;
  color: white;

}

.separator {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

