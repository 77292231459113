.card {
  text-decoration: none;
  color: black;
  @media screen and (width < 768px) {
    width: 90%;
  }
  @media screen and (width > 768px) {
    width: 75%;
  }
  margin: 20px auto;
}

.details {
  text-decoration: none;
  padding-left: 20px;
  color: black;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.detailsContainer{
  margin-top: 20px;
}


.cardImg {
  width: 100%;
}

.cardDescription {
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0 0 20px;
}