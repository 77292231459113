.langBtn {
  color: #fefefe;
  border: none;
  background: transparent;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
}

.langBtn_active {
  font-weight: 800;
  font-size: 16px;
  transition-duration: 200ms;
}

.separator {
  height: 20px;
  width: 2px;
  border-radius: 2px;
  background: white;
}

.separatorMobile{
  height: 20px;
  width: 2px;
  border-radius: 2px;
  background: black;
}

.toggleContainer {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  margin-left: 3vw;
  color: #fefefe;
}

.mobile {
  color: black;
}