.cardContainer{
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
}

.container{
  padding: 5vh;
}