.listsContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.listTitle {

}

.list {
  padding-left: 30px;
}

.listItem {
  padding-top: 10px;
}