.card {
  width: 100%; 
  max-width: 500px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5%;
  color: #131313;
}

.img {
  height: 350px; 
  width: 100%; 
  object-fit: cover; 
}
