.container {
  width: 100%;
  padding: 5vh 5vw;

}

.contentContainer {
  margin-top: 5vh;
  display: grid;
  grid-template-columns: 1.1fr 0.9fr;

  @media screen and (width < 900px) {
    grid-template-columns: 1fr;
  }


  * {
    align-self: start
  }
}

.textContainer {
  margin-top: 5vh;
}

.date{
  font-weight: 700;
  text-align: left;
}

.backBtn{
  background: transparent;
  border: none;
  margin: 5px 0 20px 10px;
  cursor: pointer;
  font-size: 18px;
}

.contents{
  margin-top: 15px;
}