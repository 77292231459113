.middleTitle {
  display: flex;
  text-align: center;
  font: {
    size: 14px;
    weight: 300;
    style: normal;
  };
  color: #F3F3F3;
  width: min(600px, 80vw);
  height: min(100px, 10vw);
  line-height: normal;
}