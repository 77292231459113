.searchResultBlock {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: max-content;
  cursor: pointer;
  text-decoration: none;
}

.resultDate {
  text-align: end;
  color: #6c6b6b;
  font-size: 12px;
}

.resultTitle {
  color: black;
}

.resultTitle:hover {
  transition-duration: 200ms;
  color: #1d522b;
  font-weight: bold;
}