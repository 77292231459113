.search {
  color: white;
}

.search:hover {
  cursor: pointer;
}

.input {
  transition-duration: 500ms;
  width: 0;
  border-radius: 100px;
  background: transparent;
  border: none;
  padding: 6px 0 6px 14px;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  outline: none;
}

.input::placeholder {
  color: white;
}

.inputActive {
  width: 10vw;
}


.container {
  display: flex;
  border-radius: 100px;
  padding-right: 10px;
  transition-duration: 500ms;
}

.containerActive {
  transition-duration: 500ms;
  border: white 1px solid;
}


.containerMobile {
  width: 100%;
  border: black 1px solid;
}



.inputMobile {
  color: black;
  width: 90%;
}

.inputMobile::placeholder{
  color:black;
}

.searchMobile{
  color:black;
}


.searchBlock {
  width: 820px;
  min-height: max-content;
  max-height: 600px;
  overflow: auto;
  background-color: rgba(255,255,255,0.6);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.searchBlockMobile {
  width: 80%;
  min-height: max-content;
  max-height: 600px;
  overflow: auto;
  background-color: rgba(255,255,255,0.6);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.resultContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px #0E3719 dashed;
  padding: 5px;

}

.searchBlock_hide {
  display: none;
}



