.container {
  margin-top: 1vh;
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
}

.container > * {
  margin-top: 5vh;
}

.blockContainer {
  padding: 5vh;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

