.titleName {
  color: #F3F3F3;
  font-size: min(14px, 3vw);
  font-weight: 700;
  text-decoration: none;
  margin-left: 2%;
}

.logo {
  width: min(100px, 15vw);
  height: min(100px, 15vw);
}

.containerTitleLogo {
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: left;
}

.skeleton {
  width: min(100px, 10vw);
  height: min(100px, 10vw);
  border-radius: 100%;
}