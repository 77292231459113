.titleContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  text-align: center;
  display: inline-block;
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 2px solid black;
}


