.navLinkB {
  position: relative;
  color: #F3F3F3;
  text-align: center;
  font-size: min(14px, 2vh);
  font-weight: 700;
  text-decoration: none;
}

.navLinkBMobile {
  color: black;
  font-size: 18px;
}


.navLinkB_active {
  border-bottom: 2px solid #F3F3F3;
  border-radius: 1px;
  padding: 8px 0;
  transition-duration: 200ms;
}
