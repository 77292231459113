
.skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: linear-gradient(90deg, #9d9d9d 25%, #6c6b6b 50%, #9d9d9d 75%);
  background-size: 200% 100%;
  animation: wave 3.5s infinite;
}

@keyframes wave {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}