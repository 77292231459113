.container {
  padding: 6vh 15vw;
  display: flex;
  flex-direction: column;
  gap: 25px;
  @media screen and (width < 900px) {
    padding: 10px;
    align-items: center;
  }
}
