.mobileHeader {
  display: flex;
  width: 100vw;
  justify-content: end;
  align-items: center;
  z-index: 20;
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  padding: 5% 3%;
  backdrop-filter: blur(5px);
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.5);
  transition-duration: 700ms;
}

.openSidebar {
  border: none;
  background: transparent;
  color: white;
  font-size: 8vw;
  z-index: 10000;
}

.openSearchBlock {
  position: absolute;
}

.openSearch {
  position: fixed;
  top: 200px;
  right: -5px;
  z-index: 20;
  width: 50px;
  height: 40px;
  border-radius: 10px 0  0 10px;
  border: none;
  background: rgba(0, 0, 0, 0.35);
  box-shadow: 2px 2px 4px 1px rgba(0.5, 0.5, 0.5, 0.5);
  color: white;
  transition-duration: 700ms;
  font-size: 20px;
}

.openSearch:hover {
  box-shadow: #1d522b 5px 5px;
}

.logoContainer {
  width: 75%;
  overflow-wrap: break-word;
  position: absolute;
  left: 2%;
  text-decoration: none;
}

.searchContainer {
  position: fixed;
  bottom: 1%;
  right: 1%;
  z-index: 40;

}
