.searchContainerMobile {
  overflow: auto;
  position: fixed;
  min-height: 100vh;
  width: 100vw;
  z-index: -1;
  display: grid;
  grid-template-columns: 2fr 8fr;
  transition-duration: 500ms;
}

.container {
  width: 80%;
  display: flex;
  border-radius: 100px;
  padding-right: 10px;
  transition-duration: 500ms;
  border: black 1px solid;
}

.input {
  transition-duration: 500ms;
  width: 100%;
  border-radius: 100px;
  background: transparent;
  border: none;
  padding: 6px 0 6px 14px;
  color: black;
  font-size: 14px;
  font-weight: 700;
  outline: none;
}

.search {
  color: black;
}

.search:hover {
  cursor: pointer;
}

.input::placeholder {
  color: black;
}

.searchMainMobile {
  padding: 20px 10px 10px 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  min-height: 100vh;
  position: absolute;
  transition-duration: 500ms;
  right: -120vw;
  z-index: 50;
  background: #fefefe;
}

.searchBlock {
  width: 100%;
  min-height: max-content;
  max-height: 800px;
  overflow: auto;
  background-color: rgba(255,255,255,0.6);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.resultContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px #0E3719 groove;
  border-top: 1px #0E3719 groove;
  padding: 5px;
}

.searchMainMobile_active{
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.5);
  right: 0;
}

.searchMobile_active {
  z-index: 40;
  background: rgba(0, 0, 0, 0.5);
}

.close_searchMobile_active {
  left: 0;
  z-index: 50;
  height: 100vh;
  width: 100%;
}


.searchMobile_close {
  position: absolute;
  left: -20vw;
}