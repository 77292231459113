.container {
  padding: 2% 5%;
  display: grid;
  @media screen and (width > 1140px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (width < 1140px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (width < 768px) {
    grid-template-columns: 1fr;
  }
}