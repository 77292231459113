.container{
  margin-top: 5vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10vh;
  padding: 0 2.5%;
}

.subContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  div {
    width: 60%;
  }
  p {
    width: 100%;
  }

  img {
    width: 30%;
    max-height: 550px;
  }

  @media screen and (width < 900px) {
    padding: 0;
    flex-direction: column;
    gap: 40px;
    img {
      order: 2;
      width: 300px;
    }
    div {
      width: 100%;
    }
    p {
      width: 100%;
    }
  }
}