.navigationTitle {
  font-weight: 700;
  text-decoration: underline;
}


.navigationContainer {
  font: {
    weight: 400;
    size: 14px;
  };
  color: #F3F3F3;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.navigationElement {
  cursor: pointer;
  text-decoration: none;
  color: white;
}



@media (max-width: 640px) {
  .navigationContainer {
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
  .navigationElement {
    text-align: center;
  }
}