.container{
  width: 100%;
}

.collapse{
  padding-top: 20px;
}

.toggleTitle {
  font-size: 24px;
  font-weight: 600;
}

.toggleContainer {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid black;
}

.toggleBtn {
  transition-duration: 300ms;
  border: none;
  background: transparent;
  font-size: 32px;
}

.toggleBtn_active {
  transform: rotate(-90deg);
}

