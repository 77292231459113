.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 10px;

}


.title {
  display: flex;
  align-items: center;
  color: black;
  font-size: 18px;
  font-weight: 700;

  * {
    transition-duration: 500ms;
  }
}

.title_active {
  transform: rotate(-90deg);
}

.link {
  margin-top: 10px;
  text-decoration: none;
  color: black;
  border-bottom: 1px solid black;
  padding-bottom: 4px;
}

.link_active {
  transition-duration: 500ms;
}