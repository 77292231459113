.image{
    width: 35px;
}

.container{
    display: flex;
    align-items: center;
    gap: 12px;
}

.link{
    color:black;
    text-decoration: underline;
}