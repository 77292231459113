.navLinkT {
  text-transform: uppercase;
  text-decoration: none;
  text-decoration-color: white;
  color: #F3F3F3;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

.navLinkTMobile{
  color:black;
  font-size: 18px;
}

.navLinkTMobile_active{
  font-weight: 800;
  color:black;
  font-size: 18px;
}

.navLinkT_active{
  font-weight: 800;
  font-size: 14px;
  transition-duration: 200ms;
}