.footerAlterContainer {
  height: min-content;
  display: flex;
  gap: 100px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 0 20px 0;
}

.footerAlterRight {
  display: flex;
  flex-direction: column;
  color: black;
  gap: 20px;
}

.footerAlterLeft {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 60px;
  width: 700px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 50px;
  background-color: white;
}

.footerButton {
  width: 150px;
  height: 40px;
  border-radius: 2px;
  background: linear-gradient(112deg, #355C40 7.61%, #0E3719 92.39%);
  color: white;
  cursor: pointer;
  border: none;
}

.footerButton:hover {
  border: 1px black groove;
}

.footerGreenContainer {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: no-repeat url('../../../../../assets/backgrounds/line.png') ;
  background-size: cover;
  width: 100%;
  color: white;
  font: {
    size: 14px;
  };
}

.footerNavigationContainer {
  display: grid;
  grid-template-columns: 300px 300px;
  gap: 20px;
  align-items: center;
}

@media (max-width: 720px) {
  .footerNavigationContainer {
    display: flex;
    flex-direction: column;
  }
  .footerAlterLeft {
    width: 500px;
    align-items: center;
  }
}

@media (max-width: 510px) {
  .footerAlterLeft {
    width: 320px;
  }
}