.line {
  height: 135%;
  width: 2px;
  background: black;
  position: absolute;
  bottom: 5%;
  left: 3%
}

.title {
  width: 100%;
  font-family: "UAF Sans";
  position: relative;
  font-size: 24px;
  font-weight: 700;
  padding: 1px 0 0 20px;
  text-align: left;

}
