.container {
  overflow: auto;
  position: fixed;
  min-height: 100vh;
  width: 100vw;
  z-index: -1;
  display: grid;
  grid-template-columns: 8fr 2fr;
  transition-duration: 500ms;
}

.logoContainer {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 2%;
}


.active {
  z-index: 40;
  background: rgba(0, 0, 0, 0.5);
}

.link {
  color: black;
}

.menu {
  padding: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 80vw;
  min-height: 100vh;
  position: absolute;
  transition-duration: 500ms;
  left: -120vw;
  z-index: 50;
  background: #fefefe;
}

.menuActive {
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.5);
  left: 0;
}

.iconsContainer {
  margin: 10px 0;
  font-size: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.close {
  position: absolute;
  right: -20vw;
}

.close_active {
  right: 0;
  z-index: 50;
  height: 100vh;
  width: 20vw;
}



