//
//.tablesContainer {
//  overflow-x: scroll;
//
//  @media screen and (width < 900px) {
//    width: 90vw;
//  }
//}
//
//.row {
//  //border: 1px solid black;
//}
//
//.rowElement {
//  border: 1px solid black;
//  text-align: center;
//}
//
//.table {
//  width: 100%;
//  border-collapse: collapse;
//  border: 1px solid black;
//}
//
//.tableElement {
//  padding: 20px;
//  text-align: center;
//}
//
//.header{
//  padding: 20px;
//  text-align: center;
//  border: 1px solid black;
//}
//
//.tableElementMany {
//  border-bottom: 1px solid black;
//}
//
//

.tablesContainer {
  overflow-x: scroll;

  @media screen and (width < 900px) {
    width: 90vw;
  }
}

.row {
  display: flex;
  justify-content: center;
  @media screen and (width < 900px) {
    width: max-content;
  }
}

.rowElement {
  border: 1px solid black;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  @media screen and (width < 900px) {
    width: 320px;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  @media screen and (width < 900px) {
    width: max-content;
  }
}

.tableElement {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.header{
  padding: 20px;
  text-align: center;
  border: 1px solid black;
}

.tableElementMany {
  border-bottom: 1px solid black;
}


