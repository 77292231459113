
.imagesContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (width < 900px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.imageContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
}

.element {
  display: flex;
  justify-content: center;
}

.img {
  margin-top: 20px;
  width: 90%;
}