.card {
  @media screen and (width > 768px) {
    width: 45%;
  }

  @media screen and (width < 768px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 5px;
  margin: 0 auto;
  text-decoration: none;
}

.cardImg {
  width: 100%;
  max-height: 340px;
}


.cardTitle {
  color: #000;
  font-size: 14px;
  font-weight: 700;
}

.cardDate {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}
