.slider {
  position: relative;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  user-select: none;
  background-color: #000;
}

.sliderPicture {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.sliderPicture::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--Green-gradient, linear-gradient(112deg, rgba(53, 92, 64, 0.55) 7.61%, rgba(14, 55, 25, 0.55) 92.39%));
  z-index: 1;
}

.sliderPicture.active::before {
  opacity: 1;
}

.sliderPicture.active {
  opacity: 1;
}

.sliderImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}


.prevButton,
.nextButton {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  padding: 0.25rem;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
}

.prevButton.left {
  left: 1.25rem;
}

.nextButton.right {
  right: 1.25rem;
}

.sliderContent {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  max-width: 1000px;
}

.sliderDescription {
  color: #F3F3F3;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
}

.sliderTitle {
  color:  #F3F3F3;
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.sliderLink {
  border-radius: 0.125rem;
  border: 0.125rem solid var(--White-Text, #F3F3F3);
  background: rgba(30, 30, 30, 0.25);
  color: var(--White-Text, #F3F3F3);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  width: auto;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 16px;
}

.bottomButton {
  z-index: 2;
  position: absolute;
  color: #fff;
  cursor: pointer;
  font-size: 2rem;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .sliderContent {
    max-width: 800px;
    padding: 8px;
  }
}

@media (max-width: 980px) {
  .sliderContent {
    max-width: 600px;
  }
}

@media (max-width: 720px) {
  .sliderContent {
    width: 100%;
    max-width: 100%;
  }
  .prevButton, .nextButton {
    top: auto;
    bottom: 0;
  }
}

@media (max-width: 540px) {
  .sliderDescription {
    font-size: 20px;
  }

  .sliderTitle {
    font-size: 28px;
  }
}

@media (max-width: 550px) {
  .sliderContent {
    max-width: 500px;
  }
}

@media (max-width: 450px) {
  .sliderDescription {
    font-size: 16px;
  }

  .sliderTitle {
    font-size: 24px;
  }
}

@media (max-width: 420px) {
  .slider {
    min-height: auto;
    height: 400px;
  }

  .bottomButton {
    display: none !important;
  }
}

.skeleton{

  width: 100vw;
  height: 100vh;
}
