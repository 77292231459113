.container {
  position: absolute;
  z-index: 10;
  width: 100%;

  @media screen and (width > 900px) {
    display: block;
  }

  @media screen and (width < 900px) {
    display: none;
  }
}

.logoLink {
  text-decoration: none;
}

.headerTopContainer {
  position: fixed;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  background: rgba(30, 30, 30, 0.25);
  backdrop-filter: blur(5px);
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.5);
  padding: 16px 5%;
  align-items: center;
  color: white;
  justify-content: space-between;
  gap: 26px;
  transition-duration: 400ms;
}

.headerBottomContainer {
  display: flex;
  align-items: start;
  top: 15vh;
  width: 100%;
  position: absolute;
  z-index: -10;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px 5%;

  @media screen and (width > 900px) {
    display: flex;
  }

  @media screen and (width < 900px) {
    display: none;
  }
}

.iconsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.linksContainer {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: min(20px, 1vw);
  align-items: center;
}

.linksContainer2 {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: min(20px, 1vw);
  align-items: center;
  width: 55%;
}

.logoContainer{
  width: 30%;
}



