.navLinkB {
  position: relative;
  color: #F3F3F3;
  text-align: center;
  font-size: min(14px, 2vh);
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}

.navLinkB_active {
  border-bottom: 2px solid #F3F3F3;
  border-radius: 1px;
  padding: 8px 0;
  transition-duration: 300ms;
}

.sublinksContainer {
  z-index: 50;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  flex-direction: column;
  position: absolute;
  height: 0;

  * {
    display: none;
  }
}


.sublinksContainer_active {
  height: auto;
  display: flex;

  * {
    display: flex;
  }

}

.triangle {
  display: none;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid white;
}

.triangle_active {
  display: block;
}

.sublink {
  text-decoration: none;
  color: #fefefe;
  font-weight: 100;
  width: 250px;
  text-align: left;
  padding: 15px;
}

.sublink:hover {
  color: black;
  background: #fefefe;
  transition-duration: 300ms;
}