.contentContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 25px;
}


.textContent {

}

