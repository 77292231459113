.middleTitle {
  display: flex;
  justify-content: center;
  text-align: center;
  font: {
    size: 14px;
    weight: 300;
    style: normal;
  };

  color: black;
  width: min(600px, 90vw);
  height: min-content;
  line-height: normal;
}