.containerMany {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    padding: 50px;

    @media screen and (width < 900px) {
        grid-template-columns: 1fr;
        padding: 30px 0;
    }
}

.containerOne {
    padding: 50px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
}

.containerOne > .video {
    height: 560px;
    width: 100%;
}

.video{
    height: 300px;
    width: 100%;
}

.emptyContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 24px;
    font-weight: 600;
}