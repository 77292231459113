@import "normalize.scss";
@import "vars.scss";


@font-face {
  font-family: 'UAF Sans';
  src: url('../../assets/fonts/UAFSans-Bold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'UAF Sans';
  src: url('../../assets/fonts/UAFSans-SemiBold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'UAF Sans';
  src: url('../../assets/fonts/UAFSans-Medium.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'UAF Sans';
  src: url('../../assets/fonts/UAFSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'UAF Sans OnBoard';
  src: url('../../assets/fonts/UAFSans-OnBoard.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'UAF Sans OnBoardStencil';
  src: url('../../assets/fonts/UAFSans-OnBoardStencil.ttf');
  font-style: normal;
}


@font-face {
  font-family: 'Volja';
  src: url('../../assets/fonts/Volja-Regular.otf');
  font-style: normal;
}

body{
  background: url("../../assets/backgrounds/layout.jpg") repeat;
  background-size: cover;
}
