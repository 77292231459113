.container {
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  color: #FEFEFE;
  font-family: Volja;
  font-size: 4.5vh;
  font-weight: 400;
  text-align: center;
  z-index: 1;
}

.filter {
  background: rgba(53, 92, 64, 0.55);
  position: absolute;
  height: 100%;
  width: 100%;
}

.none{
  display: none;
}

.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

.skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(90deg, #9d9d9d 25%, #6c6b6b 50%, #9d9d9d 75%);
  background-size: 200% 100%;
  animation: wave 3.5s infinite;
}

@keyframes wave {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.scrollDown{
    z-index: 2;
    position: absolute;
    color: #fff;
    cursor: pointer;
    font-size: 50px;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
}