.container {
  width: 47vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 900px) {
    margin-bottom: 50px;
    width: 90vw;
  }
}

.currentSlide {
  width: 100%;
  height: 400px;
  opacity: 1;
  transition-duration: 400ms;
}

.currentSlide_change {
  opacity: 0;
}

.sliderContainer {
  min-height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.leftArrow, .rightArrow {
  position: absolute;
  top: 50%;
  font-size: 24px;
  color: white;
  border: none;
  background: transparent;
  cursor: pointer;
}

.leftArrow {
  left: 5px;
}

.rightArrow {
  right: 5px;
}

.previewContainer {
  gap: 10px;
  overflow: scroll;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  max-width: max-content;
  margin: 10px auto;
  box-shadow: inset 0 0 10px 2px #333;
  border-radius: 5px;
  height: max-content;
  min-height: 120px;
}

.previewElement {
  pointer-events: none;
  height: 100px;
  width: 200px;
  border-radius: 5px;
}

.imgBtn {
  margin-top: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.imgBtn_active {
  opacity: 0.1;
}

.currentImg, .currentVideo {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
