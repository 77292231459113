.footerMainContainer {
  background: no-repeat center url('../../../../../assets/backgrounds/leather-bg.png');
  background-size: cover;
  height: min-content;
  padding-top: 50px;
}

.whiteLogo {
  color: white;
  font-size: 1.5vh;
  font-weight: 700;
  font-family: Volja;
  max-width: 300px;
  height: max-content;
}

.footerUpperContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.footerBottomContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  padding: 20px;
  align-items: start;
}

@media (max-width: 990px) {
  .footerBottomContainer {
    grid-template-columns: repeat(auto-fit, 40%);
  }
}


@media (max-width: 640px) {
  .footerBottomContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 100px;
    padding-top: 150px;
  }

}


.footerWhiteContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  text-align: center;
  padding: 10px;
  color: #000;
  font: {
    size: 14px;
    style: normal;
    weight: 700;
  };
}

