$primary: #355C40;
$secondary: #0E3719;
$green: #0E3719;
.inputContainer {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 50%;
}

.inputMain {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $green;
  outline: 0;
  font-size: 1.3rem;
  color: black;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .inputLabel {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}

.inputLabel {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: black;
}

.inputMain:focus {
  ~ .inputLabel {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $primary;
    font-weight:700;
  }
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, $primary,$secondary);
  border-image-slice: 1;
}

.inputMain:focus + .inputLabel,
.inputMain:valid + .inputLabel {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $primary;
  font-weight: 700;
}

.form__field{
  &:required,&:invalid { box-shadow:none; }
}

@media (max-width: 510px) {
  .inputContainer  {
    width: 100%;
  }
}