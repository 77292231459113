
.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.container {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
}

.marqueeContent {
  width: 100%;
  color: #F3F3F3;
  font-size: 14px;
  font-weight: 700;
  font-family: Volja;
  text-align: center;
  margin: 4px 0;
  display: inline-block;
  animation: marquee 30s linear infinite;
}

.marqueeContent > a {
  color: #F3F3F3;
  font-family: Volja;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}