.compositionCards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  padding: 10px;
}

.compositionCards > :nth-child(odd) {
  align-self: start;
}

.compositionCards > :nth-child(even) {
  align-self: end;
}

@media (max-width: 900px) {
  .compositionCards {
    align-self: center;
    padding: 0;
  }
}


