.scrollToTop {
    position: fixed;
    bottom: 3rem;
    right: 0rem;
    z-index: 1000;
    transition: 500ms;
}

.scrollButton {
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    color: white;
    border: none;
    padding: 0.4rem 0.75rem;
    border-radius: 8px 0 0  8px ;
    cursor: pointer;
    font-size: 1.5rem;
    transition: opacity 0.3s;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
}

.hidden {
    bottom: 2rem;
    right: -10rem;
}

.scrollButton:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.scrollButton:focus {
    outline: none;
}