.winContainer {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: start;
  justify-content: start;
}

.winImg {
  width: 100%;
  max-width: 1100px;
}