.container {
  background: no-repeat center url("/assets/backgrounds/leather-bg.png");
  background-size: cover;
  min-height: 310px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

