.container {
  width: 100%;
  gap: 3%;

  display: grid;
  grid-template-columns: 1fr;
  padding: 3% 5% 10% 5%;

  @media screen and (width < 1380px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    padding: 5% 2% 15% 2%;

  }
}

.subcontainer1{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.subcontainer2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: end;
  gap: 5vh;
  height: 100%;
}